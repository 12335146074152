<template>
  <div id="maintenance-page">
    <div class="centered">
      <div class="inside">
        <img alt="logo" src="@/assets/logo-uic.png" class="logo-uic" />
        <img
          alt="ปิดปรับปรุงเว็บไซต์ชั่วคราว เพื่อพัฒนาระบบ และ เพิ่มประสิทธิภาพในการให้บริการ
          ทางบริษัทขออภัยในความไม่สะดวกมา ณ ที่นี้
          หากเว็บไซต์สามารถใช้งานได้ตามปกติจะแจ้งให้ทราบอีกครั้ง
          สามารถสอบถามเพิ่มเติมได้ที่"
          src="@/assets/logo-maintenance.png"
          class="logo-maintenance"
        />
        <h2>ปิดปรับปรุงเว็บไซต์ชั่วคราว</h2>
        <p>
          เพื่อพัฒนาระบบ และ เพิ่มประสิทธิภาพในการให้บริการ
          ทางบริษัทขออภัยในความไม่สะดวกมา ณ ที่นี้
          หากเว็บไซต์สามารถใช้งานได้ตามปกติจะแจ้งให้ทราบอีกครั้ง
          สามารถสอบถามเพิ่มเติมได้ที่
        </p>
        <el-button class="btn-face-uic mg-t-5" @click="gotoLink()">
          <i class="fab fa-facebook mg-r-1"></i>
          UIC Certification Services - Thailand</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    gotoLink() {
      const link = "https://www.facebook.com/uicc.thailand/";
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = link;
      a.click();
    },
  },
};
</script>
